import { WidthContainer } from '@pata-app/core';

import React from 'react';
import { Helmet } from 'react-helmet';

import AppWrapper from '../../components/appWrapper/appWrapper';
import html from './privacy.html';

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>Pata | Our Privacy Policy</title>
        <meta
          name="description"
          content="Understand how we collect, use, and protect your personal data. Review our Privacy Policy to learn about your rights and our commitment to safeguarding your information in compliance with GDPR and other laws."
        />
      </Helmet>

      <AppWrapper>
        <WidthContainer noBackground>
          <div style={{ margin: '20px 0', maxWidth: '900px' }}>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </WidthContainer>
      </AppWrapper>
    </>
  );
};

export default PrivacyPolicy;
