import styled from 'styled-components';

export const PlacesInputContainer = styled.div`
  position: relative;
`;

export const AutoCompleteInput = styled.input`
  height: 36px;

  border: none;
  border-radius: 2px;

  color: ${({ theme }) => theme.core.colors.greyscale.dark};

  font-family: ${({ theme }) => theme.core.fontFamily.jost};
  font-weight: 600;
`;

export const PredictionsContainer = styled.div`
  display: flex;
  flex-direction: column;

  position: absolute;

  width: 100%;

  background: ${({ theme }) => theme.core.colors.greyscale.white};

  border: 1px solid ${({ theme }) => theme.core.colors.greyscale.medium};

  border-radius: 2px;

  box-shadow: rgb(0 0 0 / 28%) 0px 8px 28px;

  div {
    display: none;
  }
`;

export const Prediction = styled.button`
  width: 100%;
  height: 32px;

  background: none;

  border: none;

  text-align: left;

  font-size: ${({ theme }) => theme.core.fontSizes.s}px;

  :hover {
    background: ${({ theme }) => theme.core.colors.greyscale.light};
  }
`;
