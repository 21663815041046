import { Image, WidthContainer } from '@pata-app/core';

import React, { ReactNode } from 'react';

import {
  SectionContainer,
  ContentContainer,
  ImageBackground,
  TextBackground,
  TextContainer,
} from './textAndImageSectionWithBackground.styled';

interface ITextAndImageSectionWithBackground {
  imageSrc: string;
  imageTextAlt: string;
  children: ReactNode;
  textAlignment?: 'left' | 'right';
  backgroundColour?: string;
}

const TextAndImageSectionWithBackground = ({
  imageSrc,
  imageTextAlt,
  children,
  textAlignment = 'left',
  backgroundColour,
}: ITextAndImageSectionWithBackground) => {
  return (
    <SectionContainer backgroundColour={backgroundColour} textAlignment={textAlignment}>
      <ContentContainer textAlignment={textAlignment}>
        <WidthContainer noBackground>
          <TextContainer>{children}</TextContainer>
        </WidthContainer>
      </ContentContainer>
      <TextBackground>
        <div></div>
      </TextBackground>

      <ImageBackground backgroundColour={backgroundColour}>
        <Image src={imageSrc} alt={imageTextAlt} height={1000} width={1000} />
      </ImageBackground>
    </SectionContainer>
  );
};

export default TextAndImageSectionWithBackground;
