import styled from 'styled-components';

export const BusinessPartnersContainer = styled.section`
  > div > div {
    display: flex;
    flex-direction: column;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    > div > div {
      display: flex;
      flex-direction: row;
      align-items: center;

      height: 100%;

      * > {
        flex: 1 0 50%;
      }
    }
  }
`;

export const SectionContent = styled.div`
  padding: 64px 16px;

  > h2 {
    display: flex;
    flex-direction: column;
    gap: 5px;

    margin-top: 0;

    span {
      font-family: ${({ theme }) => theme.core.fontFamily.jost};
      font-size: ${({ theme }) => theme.core.fontSizes.xxxl}px;

      &:last-of-type {
        color: ${({ theme }) => theme.core.colors.primary.green.medium};
      }
    }
  }

  > h3 {
    font-family: ${({ theme }) => theme.core.fontFamily.openSans};
  }

  > p {
    font-size: 15px;
    line-height: 1.4;
  }

  a {
    display: block;

    width: max-content;

    text-decoration: none;

    button {
      cursor: pointer;
    }
  }

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    padding: 100px 0;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    > h2 {
      flex-direction: row;
    }
  }
`;

export const ImageContainer = styled.div`
  overflow: hidden;

  img {
    width: 100%;
    object-fit: cover;

    transform: scale(1.2) translateY(40px) translateX(-10px);
  }

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    display: flex;
    justify-content: center;
    align-self: center;

    flex: 1 0 50%;

    img {
      width: 100%;
      object-fit: cover;

      transform: none;
    }
  }

  @media ${({ theme }) => `${theme.core.breakpoints.xl}`} {
    justify-content: flex-end;
    align-self: flex-end;
    flex: 1 0 50%;

    img {
      width: 70%;
      object-fit: cover;

      transform: scale(1.2) translateY(40px) translateX(-60px);
    }
  }
`;
export const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;

  gap: ${({ theme }) => theme.core.sizing[3]}px;

  input {
    flex-grow: 1;

    height: 45px;

    background: ${({ theme }) => theme.core.colors.greyscale.white};
  }

  button {
    height: 45px;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    gap: ${({ theme }) => theme.core.sizing[2]}px;
    flex-direction: column;

    input {
      flex-grow: 1;
    }

    button {
      font-weight: 700;
    }

    button,
    input {
      height: 36px;
    }
  }

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    flex-direction: row;
  }
`;

export const Input = styled.input`
  background: #8cc4b866;
  border: 1px solid ${({ theme }) => theme.core.colors.greyscale.white};
  border-radius: 0 0 6px 0;
`;
