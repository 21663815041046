import { Icon } from '@pata-app/icons';

import React, { memo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { AnswerContainer, FaqContainer, QuestionContainer } from './faq.styled';

interface IFaq {
  question: string;
  answer: string;
}

const Faq = ({ question, answer }: IFaq) => {
  const theme = useTheme();

  const answerRef = useRef<null | HTMLDivElement>();

  const answerHeight = answerRef?.current?.scrollHeight;

  const [showAnswer, setShowAnswer] = useState(false);

  const handleFaqClick = () => {
    setShowAnswer(!showAnswer);
  };

  return (
    <FaqContainer>
      <QuestionContainer onClick={handleFaqClick} showAnswer={showAnswer}>
        {question}
        <Icon type="Arrow" fill={theme.core.colors.primary.green.medium} />
      </QuestionContainer>
      <AnswerContainer ref={answerRef} showAnswer={showAnswer} maxHeight={answerHeight}>
        {answer}
      </AnswerContainer>
    </FaqContainer>
  );
};

export default memo(Faq);
