import { Image } from '@pata-app/core';
import { WidthContainer } from '@pata-app/ui-document';
import { Button } from '@pata-app/ui-form';

import React from 'react';

import { TextWithHighlight } from '../../../../components/textWithHighlight/textWithHighlight';
import { ActionContainer, HeroContent, HeroOverviewContainer, HeroSectionContainer } from './heroSection.styled';

const HeroSection = () => {
  const handleSignUpClick = () => {
    window.location.href = `${process.env.PARTNERS_BASE_URL}/sign-up`;
  };

  const handleBookDemo = () => {
    window.location.href = `https://pata2.pipedrive.com/scheduler/DPxvorUq/pata-partners-demo`;
  };

  return (
    <HeroSectionContainer>
      <Image
        src={`${process.env.CDN_BASE_URL}/images/image-partner-app-grid.png`}
        alt="Preview of Pata Partner app on iPad"
        height={2000}
        width={2000}
      />

      <WidthContainer noBackground>
        <HeroOverviewContainer>
          <HeroContent>
            <TextWithHighlight>
              <h1>
                The fastest growing, subscription-free <span>booking platform </span>
                for pet care businesses
              </h1>
            </TextWithHighlight>
            <p>
              Make running your business easier, boost your visibility and grow your client base with Pata. Join our
              thriving marketplace, whether you offer pet grooming or pet training, dog walking or any other pet
              services.
              <br />
            </p>
            <p>
              <strong>Sign up for free </strong>
              and transform your pet care business today.
            </p>

            <ActionContainer>
              <Button onClick={handleSignUpClick}>Join for Free</Button>
              <Button buttonType="secondary" onClick={handleBookDemo}>
                Book a demo
              </Button>
            </ActionContainer>
          </HeroContent>
        </HeroOverviewContainer>
      </WidthContainer>
    </HeroSectionContainer>
  );
};

export default HeroSection;
