import React, { PropsWithChildren } from 'react';

import { SectionContainerContainer } from './sectionContainer.styled';

export default function SectionContainer({ children, title: Title }: PropsWithChildren<{ title: React.ElementType }>) {
  return (
    <SectionContainerContainer>
      <Title />
      {children}
    </SectionContainerContainer>
  );
}
