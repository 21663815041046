export const BUSINESS_CATEGORY_CONFIG = [
  { name: 'Pet Groomer', icon: 'Grooming' },
  { name: 'Dog Walker', icon: 'DogWalking' },
  { name: 'Pet Sitter', icon: 'Sitting' },
  { name: 'Pet Trainer', icon: 'Training' },
  { name: 'Veterianian', icon: 'Vet' },
  { name: 'Dog Daycare', icon: 'Daycare' },
  { name: 'Pet Boarding', icon: 'Boarding' },
  { name: 'Pet Services', icon: 'PetService' },
  { name: 'Pet Cremation', icon: 'PetCremation' },
  { name: 'Pet Hydrotherapy', icon: 'PetHydrotheraphy' },
  { name: 'Pet Transportation', icon: 'PetTransportation' },
];
