/* eslint-disable no-empty */
import { fetchAuthSession, getCurrentUser } from 'aws-amplify/auth';
import axios, { Method } from 'axios';

interface IRequest {
  endpoint: string;
  method: Method;
  body?: any;
  header?: Record<string, string>;
  authHeaders?: boolean;
}

const request = async ({ endpoint, method, body, header, authHeaders = false }: IRequest) => {
  let combinedAuthHeaders = undefined;

  try {
    if (authHeaders) {
      const session = await fetchAuthSession();

      const user = await getCurrentUser();

      combinedAuthHeaders = {
        Authorization: `Bearer ${session.tokens?.accessToken.toString()}`,
        'x-user-id': user.userId ?? '',
        ...header,
      };
    }

    return await axios({
      method,
      url: endpoint,
      headers: authHeaders ? combinedAuthHeaders : { ...header },
      data: body,
    });
  } finally {
  }
};

export default request;
