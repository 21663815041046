export const TOP_FEATURES_TITLES_CONFIG = [
  {
    title: '24/7 Online Bookings',
    description:
      'Let your customers book appointments anytime and anywhere, while you focus on things you truly enjoy.',
    icon: 'Calendar',
  },
  {
    title: 'Easy To Use Platform',
    description:
      'Say goodbye to complicated software with endless buttons - we keep it sleek and simple, with only the features you truly need.',
    icon: 'Desktop',
  },
  {
    title: 'Appointment Management',
    description: 'No more pen and paper - Pata’s simple, powerful calendar makes your day-to-day a breeze.',
    icon: 'CalendarPerson',
  },
  {
    title: 'Reach Out To New Customers',
    description: "List your business on Pata's online marketplace to reach new furry clients locally and beyond.",
    icon: 'Megaphone',
  },
  {
    title: 'No-Shows Protection',
    description: 'Secure your bookings with upfront payments and maintain full control over deposits for each service.',
    icon: 'NoShow',
  },
  {
    title: 'Automated Client Communications',
    description:
      'Keep clients informed of appointment changes, remind them of upcoming sessions, all without lifting a finger.',
    icon: 'TextMessage',
  },
];
