import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToLocation = () => {
  const scrolledRef = useRef(false);

  const { hash } = useLocation();

  useEffect(() => {
    if (hash && !scrolledRef.current) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);

      if (element) {
        const scrollOffset = -70;

        const scrollToY = element.getBoundingClientRect().top + window.pageYOffset + scrollOffset;

        window.scrollTo({ top: scrollToY, behavior: 'smooth' });
      }
    }
  });
};

export default useScrollToLocation;
