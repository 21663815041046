import { AuthProvider } from '@pata-app/ui-auth';
import { LoginModalProvider } from '@pata-app/ui-login-modal';

import { ResourcesConfig } from 'aws-amplify';
import React from 'react';
import { PropsWithChildren } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

import { endpoints, request } from '../lib/api';
import AboutUs from '../pages/aboutUs/aboutUs';
import { BusinessHome } from '../pages/businessHome/businessHome';
import ContactUs from '../pages/contactUs/contactUs';
import Error from '../pages/error/error';
import Home from '../pages/home/home';
import PrivacyPolicy from '../pages/privacyPolicy/privacyPolicy';
import TermsAndConditions from '../pages/termsAndConditions/termsAndConditions';
import '../styles/fonts.css';
import GlobalStyle from '../styles/globalStyles';
import { appTheme } from '../styles/theme';

const hostname = new URL(window.location.href).origin;

const amplifyConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: process.env.AWS_COGNITO_USER_POOL_ID || '',
      userPoolClientId: process.env.AWS_COGNITO_USER_POOL_WEB_CLIENT_ID || '',
      identityPoolId: process.env.AWS_COGNITO_IDENTITY_POOL_ID || '',
      loginWith: {
        oauth: {
          domain: process.env.AWS_COGNITO_DOMAIN || '',
          scopes: ['email', 'profile', 'openid'],
          redirectSignIn: [`${hostname}`],
          redirectSignOut: [`${hostname}/`],
          responseType: 'code',
        },
        username: true,
      },
    },
  },
};

const LoginModalWrapper = ({ children }: PropsWithChildren) => {
  const getUser = async (email: string) => {
    const response = await request({ endpoint: endpoints.userDetails(email), method: 'GET' });
    return response.data.data?.userType ?? null;
  };

  return (
    <LoginModalProvider webBaseUrl={process.env.WEB_BASE_URL} autoSignInOnRegister={true} getUser={getUser}>
      {children}
    </LoginModalProvider>
  );
};

const App = () => {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={appTheme}>
        <GlobalStyle />
        <Router>
          <AuthProvider amplifyConfig={amplifyConfig}>
            <LoginModalWrapper>
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/privacy" element={<PrivacyPolicy />} />
                <Route path="/business" element={<BusinessHome />} />
                <Route path="/terms" element={<TermsAndConditions />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/error" element={<Error />} />
                <Route path="/not-found" element={<Error errorMessage="Not Hound!" />} />
              </Routes>
            </LoginModalWrapper>
          </AuthProvider>
        </Router>
      </ThemeProvider>
    </QueryClientProvider>
  );
};

export default App;
