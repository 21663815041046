import React from 'react';

import { ImageBackground, ImageContainer } from './imageTile.styled';
import { Image } from '@pata-app/core';

interface IImageTitle {
  imageUrl: string;
  altText: string;
}

const ImageTile = ({ imageUrl, altText }: IImageTitle) => {
  return (
    <ImageContainer>
      <ImageBackground />
      <Image src={imageUrl} alt={altText} />
    </ImageContainer>
  );
};

export default ImageTile;
