import styled from 'styled-components';

export const HighlightsSectionContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: ${({ theme }) => theme.core.sizing[2]}px;

  padding: 64px 0 ${({ theme }) => theme.core.sizing[3]}px 0;

  margin: auto;
  width: 100%;

  max-width: 1500px;

  h2 {
    margin: 0;
    padding: 0 32px;

    font-family: ${({ theme }) => theme.core.fontFamily.jost};
    font-size: ${({ theme }) => theme.core.fontSizes.xxxl}px;
    color: ${({ theme }) => theme.core.colors.greyscale.black};
  }

  @media ${({ theme }) => `${theme.core.breakpoints.m}`} {
    width: 100%;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    width: 90%;

    padding: 80px 0 ${({ theme }) => theme.core.sizing[5]}px 0;
  }
`;

export const HighlightsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  gap: ${({ theme }) => theme.core.sizing[2]}px;

  padding: ${({ theme }) => theme.core.sizing[3]}px;

  @media ${({ theme }) => `${theme.core.breakpoints.m}`} {
    flex-direction: row;
    justify-content: space-around;

    width: 80%;

    padding: ${({ theme }) => theme.core.sizing[5]}px 0;
  }
`;

export const ImageContainer = styled.div`
  height: 200px;
  overflow: hidden;

  img {
    width: 100%;
    object-fit: cover;

    transform: scale(1.5) translateY(20px);
    transform-origin: left center;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    height: initial;

    grid-column: 1 / span 3;
    grid-row: 1 / span 3;

    width: 100%;
  }
`;

export const OwnerHighlightsContainer = styled.section`
  display: flex;
  flex-direction: column;

  overflow: hidden;

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    display: grid;

    grid-template-columns: 10% minmax(0, 80%) 10%;
    grid-template-rows: 10% 80% 10%;

    height: 500px;

    img {
      transform: none;
      transform-origin: none;
    }
  }

  @media ${({ theme }) => `${theme.core.breakpoints.m}`} {
  }
`;

export const HighlightsContentContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;

  align-self: end;
  justify-self: end;

  max-width: 1500px;
  width: 100%;
  height: 100%;

  margin: auto;
`;

export const HighlightsContent = styled.div`
  height: min-content;
  width: 100%;

  background: ${({ theme }) => theme.core.colors.greyscale.white};

  padding: ${({ theme }) => theme.core.sizing[2]}px;
  h2 {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.core.sizing[1]}px;

    margin: 0;

    font-size: ${({ theme }) => theme.core.fontSizes.xxxl}px;

    > span:last-of-type {
      > span:last-of-type {
        color: ${({ theme }) => theme.core.colors.primary.green.medium};
      }
    }
  }

  p {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.core.sizing[2]}px;
    font-size: 15px;

    svg {
      fill: ${({ theme }) => theme.core.colors.primary.green.medium};
    }
  }

  @media ${({ theme }) => `${theme.core.breakpoints.m}`} {
    padding: ${({ theme }) => theme.core.sizing[5]}px;
    width: 50%;
    min-width: 500px;

    border-radius: ${({ theme }) => theme.core.sizing[1]}px;

    border-left: 12px solid ${({ theme }) => theme.core.colors.primary.green.medium};
  }
`;
