export const faqsConfig = [
  {
    question: 'Can I access Pata on multiple devices?',
    answer: 'Yes! You can log into your account from multiple devices.',
  },
  {
    question: 'Is support available?',
    answer:
      'Absolutely! Our Help Centre has everything you need to know about Pata and how to use the platform. If you can’t find what you’re looking for, our Customer Success Team are also available Monday to Friday 9am-5:30pm to assist with any queries you may have.',
  },
  {
    question: 'Is my data secure and backed up?',
    answer:
      "Your data is kept electronically on a secure, encrypted database with limited access; only limited employees of Pata are able to access this information in case of troubleshooting, or removing your data. Your data is also backed up every 24 hours. To see more on the data we collect, use, and how it's stored, please read our Privacy Policy for full transparency.",
  },
  {
    question: 'I want to move my data from a different app/software, do you offer system migrations?',
    answer:
      'Of course! We know how laborious it can be to manually transfer data, so we want to help make it as quick and easy as possible to help you get set up on Pata. Contact us to get started and we’ll let you know what we need.',
  },
  {
    question: 'Is Pata available worldwide?',
    answer:
      'Pata is accessible anywhere in the world, however, we currently only operate in the UK (England, Ireland, Scotland, Wales and Northern Ireland), supporting the English language. We are working really hard to extend our reach and expand the Marketplace further, so keep an eye out!',
  },
];
