import React from 'react';
import styled from 'styled-components';

import ImageTile from '../../../../components/imageTile/imageTile';

interface IMissionTile {
  imageUrl: string;
  title: string;
  description: string;
}

const ContactTitleContainer = styled.div`
  display: grid;
  grid-auto-rows: 220px auto auto;

  text-decoration: none;

  color: ${({ theme }) => theme.core.colors.greyscale.black};

  h3 {
    margin-bottom: 0;
    margin-top: ${({ theme }) => theme.core.sizing[4]}px;

    font-size: ${({ theme }) => theme.core.fontSizes.m}px;
  }

  p {
    margin: 0;
  }
`;

const MissionTile = ({ imageUrl, title, description }: IMissionTile) => {
  return (
    <ContactTitleContainer>
      <ImageTile imageUrl={imageUrl} altText={`${title} image`} />
      <h3>{title}</h3>
      <p>{description}</p>
    </ContactTitleContainer>
  );
};

export default MissionTile;
