import { createGlobalStyle } from 'styled-components';
import { coreGlobalStyles, appTheme as designSystemAppTheme } from '@pata-app/themes';
import { createStyledBreakpointsTheme } from 'styled-breakpoints';

const colors = {
  black: '#000000',
  white: '#FFFFFF',

  // Alerts
  error: '#D32F2F',
  info: '#0074e6',
  lightBlue: '#e5f2ff',
  lightGreen: '#eaf6ed',
  orange: '#f57f17',
  success: '#2e7b32',
  successAlt: '#00a33c',
  warning: '#fdd835',

  // Greyscale
  darkGrey: '#4b4b4b',
  grey: '#757575',
  lightGrey: '#f2f2f2',
  lighterGrey: '#f5f5f5',
  mediumGrey: '#d8d8d8',

  // Pata Colours
  pataBlue: '#117191',
  pataGreen: '#8cc4b8',
  pataTeal: '#90cfd1',
  pataOrange: '#f5a046',
  pataYellow: '#f5c14f',
};


  

export const breakpointTheme = createStyledBreakpointsTheme();

export const appTheme = {
  ...designSystemAppTheme,
  ...breakpointTheme,
  colors: {
    primary: {
      blue: colors.pataBlue,
      green: colors.pataGreen,
      teal: colors.pataTeal,
    },
    secondary: {
      orange: colors.pataOrange,
      yellow: colors.pataYellow,
    },
    greyscale: {
      white: colors.white,
      lighter: colors.lighterGrey,
      light: colors.lightGrey,
      medium: colors.mediumGrey,
      dark: colors.grey,
      darker: colors.darkGrey,
      black: colors.black,
    },
    info: {
      light: colors.lightBlue,
      medium: colors.info,
    },
    success: {
      light: colors.lightGreen,
      medium: colors.successAlt,
      dark: colors.success,
    },
    warning: {
      medium: colors.warning,
      dark: colors.orange,
    },
    danger: {
      medium: colors.error,
    },
  },
};


export const GlobalStyles = createGlobalStyle`
${coreGlobalStyles}

html, body, #root {
    height: 100%;

    color: ${({ theme }) => theme.core.colors.greyscale.black};
    
}

a {
  color: ${({ theme }) => theme.core.colors.info.medium};
  text-decoration: none;
}

button {
  &:active { 
    transform: scale(0.99);
  } 
}
`;

export type TAppTheme = typeof appTheme;
