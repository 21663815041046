import { WidthContainer } from '@pata-app/core';

import React from 'react';
import Helmet from 'react-helmet';

import AppWrapper from '../../components/appWrapper/appWrapper';
import html from './termsAndConditions.html';
import { TermsContainer } from './termsAndConditions.styled';

const TermsAndConditions = () => {
  return (
    <>
      <Helmet>
        <title>Pata | Our Terms and Conditions</title>
        <meta
          name="description"
          content="Review the Terms of Use for Pata to understand your rights and responsibilities when using our services. Learn about our policies on payments, privacy, and acceptable use."
        />
      </Helmet>
      <AppWrapper>
        <WidthContainer noBackground>
          <TermsContainer style={{ maxWidth: '900px' }}>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </TermsContainer>
        </WidthContainer>
      </AppWrapper>
    </>
  );
};

export default TermsAndConditions;
