const groupDataByKey = (data: any, groupByKey: string) => {
  return data.reduce((groupedData, item) => {
    return {
      ...groupedData,
      [item[groupByKey]]: [...(groupedData[item[groupByKey]] ? groupedData[item[groupByKey]] : []), item],
    };
  }, {});
};

export default groupDataByKey;
