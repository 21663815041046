import { Image, WidthContainer } from '@pata-app/core';

import React from 'react';
import { Helmet } from 'react-helmet';

import AppWrapper from '../../components/appWrapper/appWrapper';
import ContactTile from './componenta/contactTile/contactTile';
import { ContactUsConfig } from './contactUs.config';
import {
  ContactTileContainer,
  ContactUsContainer,
  ContactUsDescription,
  HeroSectionContainer,
} from './contactUs.styled';

const ContactUs = () => {
  return (
    <>
      <Helmet>
        <title>Pata | Contact us</title>
        <meta
          name="description"
          content="Have questions or need assistance? Get in touch with our amazing team. We're here to help with enquiries, support, or feedback about our services."
        />
      </Helmet>
      <AppWrapper>
        <HeroSectionContainer>
          <h1>CONTACT US</h1>
          <h2>Can't find what you need?</h2>

          <Image
            src={`${process.env.CDN_BASE_URL}/images/image-dog-being-stroked.webp`}
            alt="Dog being stroked"
            height={1000}
            width={1000}
          />
        </HeroSectionContainer>
        <WidthContainer noBackground>
          <ContactUsContainer>
            <ContactUsDescription>
              Questions? Feedback? Our team is here to help! We want to hear from you - whether it's getting assistance
              with our app, sharing suggestions, or telling us about your experience. Your thoughts help us continously
              improve to better serve pet parents' needs.
            </ContactUsDescription>
            <ContactTileContainer>
              {ContactUsConfig.map((contactDetail) => (
                <ContactTile
                  key={contactDetail.title}
                  imageUrl={contactDetail.imageUrl}
                  title={contactDetail.title}
                  description={contactDetail.description}
                  emailLink={contactDetail.emailLink}
                />
              ))}
            </ContactTileContainer>
          </ContactUsContainer>
        </WidthContainer>
      </AppWrapper>
    </>
  );
};

export default ContactUs;
