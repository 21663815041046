export const SIGN_UP_PROCESS_CONFIG = [
  {
    title: '1. Create an account',
    description: 'Joining Pata is a quick and easy process which does not require credit card details.',
  },
  {
    title: '2. Set up your business',
    description: 'Add your services, team members and availability to accept bookings.',
  },
  {
    title: '3. Enable online payments',
    description: 'Say no to cancellations and no-shows and yes to the seamless customer experience.',
  },
  {
    title: "4. You're ready to nail it!",
    description: 'Boost your visibility to clients via Pata Marketplace and get those appointments coming!',
  },
];
