import { BusinessTile as BusinessTileComponent } from '@pata-app/ui-business';

import React from 'react';

import { useGetConfig } from '../../../../../queries/useGetConfig';
import { BusinessSearchItem } from '../../../../../types/business.types';

interface BusinessTileProps {
  business: BusinessSearchItem;
  index: number;
}

export function BusinessTile({ business, index }: BusinessTileProps) {
  const { data: businessCategory } = useGetConfig({
    select: (data) => data.config.categories.find((category) => category.id === business.mainCategory),
  });

  return (
    <BusinessTileComponent
      backgroundImage={
        business.media?.length
          ? `${process.env.CDN_BASE_URL_USER_CONTENT}/${business.media[0]}`
          : `${process.env.CDN_BASE_URL}/images/image-provider-category-${business.mainCategory}-${
              (index % 15) + 1
            }.webp`
      }
      bookNowLink={
        business.bookingType === 'online' &&
        `${process.env.WEB_BASE_URL}/booking/${business.accountId}/${business.workplaceId}/pets`
      }
      profileUrl={`${process.env.WEB_BASE_URL}/p/${business.slug}`}
      businessAddress={business.formattedAddress}
      businessCategory={businessCategory.name}
      id={business.id}
      businessName={business.name}
    />
  );
}
