import styled from 'styled-components';

export const TopFeatureTileContainer = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    margin: 0;
  }

  svg {
    height: 40px;
    width: 40px;

    fill: ${({ theme }) => theme.core.colors.primary.green.medium};

    margin-bottom: ${({ theme }) => theme.core.sizing[1]}px;
  }
`;
