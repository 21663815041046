export type TMethods = typeof methods

const methods = {
  GET: 'GET',
  POST: 'POST',
  PUT: 'PUT',
  HEAD: 'HEAD',
  PATCH: 'PATCH',
} as const

export default methods
