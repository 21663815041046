import { Image } from '@pata-app/core';

import React, { PropsWithChildren } from 'react';

import { FeatureTileContainer, FeatureTileContent } from './featureTile.styled';

interface FeatureTileProps {
  image: string;
  alignment: 'left' | 'right';
}

export function FeatureTile({ image, alignment, children }: PropsWithChildren<FeatureTileProps>) {
  return (
    <FeatureTileContainer $alignment={alignment}>
      {/* <Image src={image} /> */}
      {children}
    </FeatureTileContainer>
  );
}
