import { Image } from '@pata-app/core';
import { Icon } from '@pata-app/icons';
import { WidthContainer } from '@pata-app/ui-document';
import { Button } from '@pata-app/ui-form';

import React from 'react';
import { useTheme } from 'styled-components';

import { TextWithHighlight } from '../../../../components/textWithHighlight/textWithHighlight';
import { ActionContainer } from '../heroSection/heroSection.styled';
import { businessBenefits } from './businessBenefits.config';
import { BusinessBenefit, BusinessBenefitsContainer, BusinessBenefitsContent } from './businessBenefits.styled';

const BusinessBenefits = () => {
  const theme = useTheme();

  const handleSignUpClick = () => {
    window.location.href = `${process.env.PARTNERS_BASE_URL}/sign-up`;
  };

  const handleBookDemo = () => {
    window.location.href = `https://pata2.pipedrive.com/scheduler/DPxvorUq/pata-partners-demo`;
  };

  return (
    <BusinessBenefitsContainer>
      <Image
        src={`${process.env.CDN_BASE_URL}/images/image-dog-jumping-3.jpeg`}
        alt="Dogs running"
        height={1500}
        width={2500}
      />
      <WidthContainer noBackground>
        <BusinessBenefitsContent>
          <h2>
            <TextWithHighlight>
              No complicated subscription plans, no hidden costs. <span>Simply free.</span>
            </TextWithHighlight>
          </h2>

          <p>
            We’re here to support thousands of UK pet businesses in showcasing their services to clients, saving time
            and removing financial burdens – Pata is the only subscription-free platform on the market.
          </p>

          <ul>
            {businessBenefits.map((benefit) => (
              <BusinessBenefit key={benefit.title}>
                <Icon type="Tick" fill={theme.core.colors.primary.green.medium} size="18px" />
                <strong>{benefit.title}</strong>
                <p>{benefit.description}</p>
              </BusinessBenefit>
            ))}
          </ul>

          <ActionContainer>
            <Button onClick={handleSignUpClick}>Sign Up Today</Button>
            <Button buttonType="secondary" onClick={handleBookDemo}>
              Book a Demo
            </Button>
          </ActionContainer>
        </BusinessBenefitsContent>
      </WidthContainer>
    </BusinessBenefitsContainer>
  );
};

export default BusinessBenefits;
