import styled from 'styled-components';

export const BusinessBenefitsContainer = styled.section`
  display: grid;

  background: ${({ theme }) => theme.core.colors.primary.green.medium};

  img {
    width: 100%;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 30px auto 30px;

    overflow: hidden;
    > div {
      display: flex;
      justify-content: flex-end;
      grid-column: 1 / span 4;
      grid-row: 2 / span 1;
    }

    img {
      grid-column: 1 / span 3;
      grid-row: 1 / span 3;
      object-fit: cover;

      max-height: 600px;
      width: 100%;
    }
  }
`;

export const BusinessBenefitsContent = styled.div`
  height: min-content;

  border-radius: ${({ theme }) => theme.core.border.container.radius}px;

  background: ${({ theme }) => theme.core.colors.greyscale.white};

  align-self: center;

  margin: ${({ theme }) => theme.core.sizing[2]}px;
  padding: ${({ theme }) => theme.core.sizing[2]}px;

  h2 {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.core.sizing[0]}px;
    font-size: ${({ theme }) => theme.core.fontSizes.xxl}px;

    margin: 0;
    > span {
      font-family: ${({ theme }) => theme.core.fontFamily.jost};

      &:last-of-type {
        span {
          color: ${({ theme }) => theme.core.colors.primary.green.medium};
        }
      }
    }
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.core.sizing[2]}px;

    padding: 0;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    padding: ${({ theme }) => theme.core.sizing[4]}px ${({ theme }) => theme.core.sizing[4]}px
      ${({ theme }) => theme.core.sizing[4]}px 64px;

    margin: 16px 0;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    width: 50%;

    margin: 0;
    margin-top: initial;
  } ;
`;

export const BusinessBenefit = styled.li`
  display: grid;
  grid-template-columns: 30px auto;
  grid-template-rows: auto auto;
  column-gap: ${({ theme }) => theme.core.sizing[2]}px;

  list-style: none;

  strong {
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
    font-family: ${({ theme }) => theme.core.fontFamily.openSans};
    font-size: 15px;
  }
  p {
    grid-column: 2 / span 1;
    grid-row: 2 / span 1;
    margin: 0;
    font-family: ${({ theme }) => theme.core.fontFamily.openSans};
  }

  svg {
    flex: 0 0 auto;
  }
`;
