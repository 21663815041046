import { WidthContainer } from '@pata-app/core';

import React from 'react';

import { NewIcon } from '../../../../components/newIcon/icon';
import SectionContainer from '../../../../components/sectionContainer/sectionContainer';
import { TextWithHighlight } from '../../../../components/textWithHighlight/textWithHighlight';
import { BUSINESS_CATEGORY_CONFIG } from './businessCategories.config';
import { CategoriesContainer, CategoryContainer } from './businessCategories.styled';

const BusinessCategoriesTitle = () => {
  return (
    <TextWithHighlight>
      <h2 style={{ textAlign: 'center' }}>Which pet service are you looking for today?</h2>
    </TextWithHighlight>
  );
};

export function BusinessCategories() {
  return (
    <WidthContainer noBackground>
      <SectionContainer title={BusinessCategoriesTitle}>
        <CategoriesContainer>
          {BUSINESS_CATEGORY_CONFIG.map((category) => (
            <CategoryContainer
              href={`${process.env.WEB_BASE_URL}/p/search?searchterm=${category.id}&searchtermtype=category`}
            >
              <NewIcon size="32px" type={category.icon} />
              {category.name}
            </CategoryContainer>
          ))}
        </CategoriesContainer>
      </SectionContainer>
    </WidthContainer>
  );
}
