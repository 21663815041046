import styled from 'styled-components';

export const FaqsSection = styled.div`
  display: flex;
  flex-direction: column;

  padding: ${({ theme }) => theme.core.sizing[5]}px ${({ theme }) => theme.core.sizing[4]}px;

  > * {
    flex: 1 0 50%;
  }

  h2 {
    display: flex;
    flex-direction: column;
    gap: ${({ theme }) => theme.core.sizing[1]}px;

    margin: 0;

    span {
      font-family: ${({ theme }) => theme.core.fontFamily.jost};
      font-size: ${({ theme }) => theme.core.fontSizes.xxl}px;

      color: ${({ theme }) => theme.core.colors.greyscale.black};

      &:last-of-type {
        span {
          color: ${({ theme }) => theme.core.colors.primary.green.medium};
        }
      }
    }
  }

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    flex-direction: row;

    padding: ${({ theme }) => theme.core.sizing[5]}px 0;
  }
`;

export const FaqsContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  hr {
    border: 0;
    border-top: 1px ${({ theme }) => theme.core.colors.primary.green.medium} solid;
    margin: 0;
  }
`;

export const HelpCentreLink = styled.p`
  font-family: ${({ theme }) => theme.core.fontFamily.jost};
  font-size: ${({ theme }) => theme.core.fontSizes.l}px;
  a {
    cursor: pointer;

    font-weight: ${({ theme }) => theme.core.fontWeight.medium};

    color: ${({ theme }) => theme.core.colors.primary.blue.medium};

    text-decoration: none;
  }
`;
