import styled from 'styled-components';

export const SearchBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: ${({ theme }) => theme.core.sizing[3]}px;

  background: ${({ theme }) => theme.core.colors.greyscale.white};
  width: 100%;

  padding: ${({ theme }) => theme.core.sizing[1]}px;

  border-radius: 8px;

  > button {
    justify-self: flex-end;
    width: 100%;

    cursor: pointer;
  }

  input {
    height: 45px;
    flex-grow: 1;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.m}`} {
    flex-direction: row;

    width: 100%;

    button,
    input {
      height: 36px;
    }

    > button {
      width: 150px;
    }
  }
  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    flex-direction: row;

    min-width: 800px;
    width: 500px;
  }
`;
