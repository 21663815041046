import { useQuery } from 'react-query';

import { getBusinessSearch } from '../api/getBusinessSearch';
import { BusinessSearchItem } from '../types/business.types';

export function useGetBusinesses() {
  return useQuery<BusinessSearchItem[], Error>({
    queryKey: 'businesses',
    queryFn: getBusinessSearch,
  });
}

export function useGetGroomingBusinesses() {
  return useQuery<BusinessSearchItem[], Error>({
    queryKey: 'groomingBusinesses',
    queryFn: () => getBusinessSearch('pet-grooming'),
  });
}

export function useGetTrainingBusinesses() {
  return useQuery<BusinessSearchItem[], Error>({
    queryKey: 'trainingBusinesses',
    queryFn: () => getBusinessSearch('pet-training'),
  });
}
