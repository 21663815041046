import { Image } from '@pata-app/core';

import React from 'react';

import {
  HighlightsContent,
  HighlightsContentContainer,
  ImageContainer,
  OwnerHighlightsContainer,
} from './highlightsSection.styled';

const HighlightsSection = () => {
  return (
    <OwnerHighlightsContainer>
      <ImageContainer>
        <Image height={1500} src="https://static.patacdn.net/images/image-lady-grooming-dog-wide.png" />
      </ImageContainer>
      <HighlightsContentContainer>
        <HighlightsContent>
          <h2>
            <span>Your pet's next appointment,</span>
            <span>
              <span>just a click away</span>
            </span>
          </h2>
          <p>Looking for your next pet groomer, trainer, dog walker, or cat sitter?</p>
          <p>
            With Pata, finding and booking the best pet professionals is easy, free, and just a few clicks away. No more
            back and forth calls and messages, book anytime, anywhere, 24/7.
          </p>
          <p>Explore the best pet care businesses in your area and book instantly with Pata.</p>
        </HighlightsContent>
      </HighlightsContentContainer>
    </OwnerHighlightsContainer>
  );
};

export default HighlightsSection;
