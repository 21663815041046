const endpoints = {
  businessProfile: (businessId: string) => `${process.env.API_ROOT_URL}/businesses/${businessId}`,
  businessReviews: (businessId: string) => `${process.env.API_ROOT_URL}/businesses/${businessId}/reviews`,
  businessSearch: `${process.env.API_ROOT_URL}/providers/search`,
  config: `${process.env.API_ROOT_URL}/config/categories`,
  newsletter: `${process.env.API_ROOT_URL}/marketing/newsletter/subscribe`,
  user: (id: string) => `${process.env.API_USER_ROOT_URL}/users/${id}`,
  userDetails: (email: string) => `${process.env.API_USER_ROOT_URL}/users?email=${email}`,
};

export default endpoints;
