import styled from 'styled-components';

export const HeroSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 ${({ theme }) => theme.core.sizing[4]}px ${({ theme }) => theme.core.sizing[3]}px
    ${({ theme }) => theme.core.sizing[4]}px;

  background: ${({ theme }) => theme.core.colors.secondary.green.medium};
  color: ${({ theme }) => theme.core.colors.greyscale.white};

  h1 {
    margin: 0;

    font-size: ${({ theme }) => theme.core.fontSizes.s}px;
  }

  h2 {
    margin-top: ${({ theme }) => theme.core.sizing[1]}px;

    font-family: ${({ theme }) => theme.core.fontFamily.jost};
    font-size: ${({ theme }) => theme.core.fontSizes.xxxl}px;

    text-align: center;
  }

  img {
    width: 100%;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.m}`} {
    img {
      width: 800px;
    }
  }
`;

export const AboutUsDescription = styled.p`
  margin: 0;

  margin: 60px ${({ theme }) => theme.core.sizing[4]}px 0;
  text-align: center;

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    width: 80%;

    margin: 60px auto 0;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    width: 60%;

    margin: 60px auto 0;
  }
`;

export const ContactTileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  margin: 0 ${({ theme }) => theme.core.sizing[4]}px 60px;

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    margin: 0 auto 60px;

    width: 100%;
    max-width: 400px;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    flex-direction: row;

    width: 80%;
    max-width: none;
  }
`;

export const CharityContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60px;

  padding: 60px ${({ theme }) => theme.core.sizing[4]}px;

  h2 {
    display: flex;
    flex-direction: column;

    margin-top: 0;

    span {
      font-family: ${({ theme }) => theme.core.fontFamily.jost};
      font-size: ${({ theme }) => theme.core.fontSizes.xxxl}px;
    }
  }

  p {
    margin-bottom: 0;
  }

  img {
    width: 100%;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    display: flex;
    flex-direction: row;
    gap: 0;

    height: 400px;

    padding: 60px 0;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      flex: 0 1 50%;

      &:last-of-type {
        align-items: center;
      }
    }

    img {
      width: 80%;
    }
  }

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    img {
      width: 60%;
      min-width: 400px;
    }
  }
`;

export const SectionHeader = styled.h2`
  text-align: center;

  margin: 60px auto 16px;

  font-weight: 700;
  font-size: ${({ theme }) => theme.core.fontSizes.m}px;
`;
