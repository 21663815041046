import React from 'react';

import { NewIcon } from '../../../../components/newIcon/icon';
import { TopFeatureTileContainer } from './topFeatureTile.styled';

interface TopFeatureTileProps {
  title: string;
  description: string;
  icon: string;
}

export default function TopFeatureTile({ title, description, icon }: TopFeatureTileProps) {
  return (
    <TopFeatureTileContainer>
      <NewIcon type={icon} />
      <h3>{title}</h3>
      <p>{description}</p>
    </TopFeatureTileContainer>
  );
}
