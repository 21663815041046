export const BUSINESS_CATEGORY_CONFIG = [
  { name: 'Pet Groomer', icon: 'Grooming', id: 'pet-grooming' },
  { name: 'Dog Walker', icon: 'DogWalking', id: 'dog-walking' },
  { name: 'Pet Sitter', icon: 'Sitting', id: 'pet-sitter' },
  { name: 'Pet Trainer', icon: 'Training', id: 'pet-trainer' },
  { name: 'Veterianian', icon: 'Vet', id: 'veterinary-practice' },
  { name: 'Dog Daycare', icon: 'Daycare', id: 'dog-daycare' },
  { name: 'Pet Boarding', icon: 'Boarding', id: 'pet-boarding' },
  { name: 'Pet Services', icon: 'PetService', id: 'pet-services' },
  { name: 'Pet Cremation', icon: 'PetCremation', id: 'pet-cremation' },
  { name: 'Pet Hydrotherapy', icon: 'PetHydrotheraphy', id: 'pet-hydrotheraphy' },
  { name: 'Pet Transportation', icon: 'PetTransportation', id: 'pet-transportation' },
];
