type TQueryStringObject = Record<string, string>;

const generateQueryString = (queryStringObject: TQueryStringObject) => {
  if (Object.keys(queryStringObject).length > 0) {
    return `?${Object.keys(queryStringObject).reduce((queryString, key) => {
      return queryStringObject[key]
        ? `${queryString}&${key}=${encodeURIComponent(queryStringObject[key])}`
        : queryString;
    }, '')}
      `;
  }
  return '';
};

export default generateQueryString;
