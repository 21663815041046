import styled from 'styled-components';

export const ImageContainer = styled.div`
  position: relative;

  padding-right: 12px;

  img {
    position: relative;
    object-fit: cover;

    width: 100%;
    height: 100%;

    z-index: 1;
  }
`;

export const ImageBackground = styled.div`
  position: absolute;

  width: calc(100% - 12px);
  height: 100%;

  top: 12px;
  left: 12px;

  background: ${({ theme }) => theme.core.colors.primary.green.medium};
`;
