import React from 'react';

import Icons from './_svg';
import { StyledIcon } from './icon.styled';
import { IIcon } from './icon.types';

const DEFAULT_ICON_SIZE = '20px';

const Icon = ({ type, fill, size = DEFAULT_ICON_SIZE }: IIcon) => {
  const SelectedIcon = Icons[type];

  if (!SelectedIcon) {
    console.error(`Icon with type ${type} does not exist!`);
    return null;
  }

  return <StyledIcon as={SelectedIcon} size={size} fill={fill} />;
};

export default Icon;
