import styled from 'styled-components';

interface IconElementProps {
  $size: string;
  $fill: string;
}

export const IconElement = styled.svg<IconElementProps>`
  height: ${({ $size }) => $size};
  width: ${({ $size }) => $size};
  min-height: ${({ $size }) => $size};
  min-width: ${({ $size }) => $size};

  fill: ${({ $fill }) => $fill};
`;
