import React from 'react';

import { OurStoryContainer } from './ourStory.styled';

const OurStory = () => {
  return (
    <OurStoryContainer>
      <h2>Our Story.</h2>
      <h3>Built by pet parents for pet parents</h3>
      <p>
        Our journey began when our two founders bonded over the trials and tribulations of being pet parents. We
        struggled firsthand with finding reliable services, advice, and care for our furry companions. Managing vet
        visits, training tips, medication schedules – it was overwhelming at times! We knew there had to be a better
        way.
      </p>

      <p>
        Driven by a shared passion to de-stress pet parenthood, we embarked on a mission to create an app providing
        solutions we wished we had. And so Pata was born! Built by pet parents for pet parents, our app simplifies it
        all. Now fellow pet owners can seamlessly access information, services and communities tailored to their pets'
        needs.
      </p>
    </OurStoryContainer>
  );
};

export default OurStory;
