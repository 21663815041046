import { WidthContainer } from '@pata-app/core';

import React, { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import { useGetBusinesses } from '../../../../hooks/useGetBusinessSearch';
import { BusinessCarouselContainer, BusinessTilesContainer } from './businessCarousel.styled';
import { BusinessTile } from './components/businessTile';

function BusinessCarousel() {
  const { data: businessData, isLoading: businessDataLoading } = useGetBusinesses();

  const petbusinessData = useMemo(
    () => businessData?.sort((a, b) => (a.bookingType === 'online' && b.bookingType !== 'online' ? -1 : 1)),
    [businessData],
  );
  // const petTrainerBusinesses = useMemo(
  //   () => trainingData?.sort((a, b) => (a.bookingType === 'online' && b.bookingType !== 'online' ? -1 : 1)),
  //   [trainingData],
  // );

  return (
    <BusinessCarouselContainer id="newsletter">
      <WidthContainer>
        <BusinessTilesContainer>
          <h2>
            Pet services <span>near you</span>
          </h2>
          {Boolean(petbusinessData?.length) && (
            <div>
              {petbusinessData?.map((business, index) => (
                <BusinessTile key={business.id} index={index} business={business} />
              ))}
            </div>
          )}

          {businessDataLoading && (
            <div>
              <Skeleton height={'285px'} count={1} />
              <Skeleton height={'285px'} count={1} />
              <Skeleton height={'285px'} count={1} />
              <Skeleton height={'285px'} count={1} />
              <Skeleton height={'285px'} count={1} />
            </div>
          )}
        </BusinessTilesContainer>
        {/* <BusinessTilesContainer>
          <h2>
            Pet trainers <span>near you</span>
          </h2>
          {Boolean(petTrainerBusinesses?.length) && (
            <div>
              {petTrainerBusinesses?.map((business, index) => (
                <BusinessTile key={business.id} index={index} business={business} />
              ))}
            </div>
          )}
          {trainingLoading && (
            <div>
              <Skeleton height={'285px'} count={1} />
              <Skeleton height={'285px'} count={1} />
              <Skeleton height={'285px'} count={1} />
              <Skeleton height={'285px'} count={1} />
              <Skeleton height={'285px'} count={1} />
            </div>
          )}
        </BusinessTilesContainer> */}
      </WidthContainer>
    </BusinessCarouselContainer>
  );
}

export default BusinessCarousel;
