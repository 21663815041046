import styled from 'styled-components';

interface IBackgroundColour {
  backgroundColour: string;
}

interface ISection extends IBackgroundColour {
  textAlignment: 'left' | 'right';
}
interface IContentContainer {
  textAlignment: 'left' | 'right';
}

export const SectionContainer = styled.section<ISection>`
  position: relative;

  display: flex;
  flex-direction: column;

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    background: ${({ backgroundColour }) => backgroundColour};
  }

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    flex-direction: ${({ textAlignment }) => (textAlignment === 'left' ? 'row-reverse' : 'row')};

    height: 500px;
  }
`;

export const TextBackground = styled.div`
  display: none;

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 1 0 50%;

    height: 80%;

    margin: auto;

    > div {
      width: 100%;
      max-width: 1000px;
      height: 100%;

      background: ${({ theme }) => theme.core.colors.greyscale.white};
    }
  }
`;

export const TextContainer = styled.div`
  > h2 {
    display: flex;
    flex-direction: column;

    margin-top: 0;

    font-size: ${({ theme }) => theme.core.fontSizes.xxl}px;

    span {
      font-family: ${({ theme }) => theme.core.fontFamily.jost};
      font-size: ${({ theme }) => theme.core.fontSizes.xxxl}px;
    }
  }

  > p {
    line-height: 1.4;
  }

  button {
    height: 45px;

    font-weight: 700;
  }

  a {
    text-decoration: none;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    width: 80%;

    margin-top: 40px;

    background: ${({ theme }) => theme.core.colors.greyscale.white};

    button {
      height: 36px;
    }
  }

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    width: 50%;

    background: transparent;

    margin-top: 0;

    button {
      height: 36px;
    }
  }
`;

export const ImageBackground = styled.div<IBackgroundColour>`
  height: 255px;

  overflow: hidden;
  background: ${({ backgroundColour }) => backgroundColour};

  img {
    width: 100%;
    height: 100%;
    max-width: 1200px;

    object-fit: cover;
    object-position: right;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    display: flex;
    justify-content: flex-end;

    flex: 1 0 50%;

    height: auto;
  }
`;

export const ContentContainer = styled.div<IContentContainer>`
  display: flex;
  align-items: center;

  > div {
    display: flex;
    justify-content: center;

    margin: 0 32px 32px;
    padding: 64px 0 32px 0;

    border-top: 1px solid ${({ theme }) => theme.core.colors.greyscale.medium};
  }

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    justify-content: center;
    flex-direction: column;

    width: 100%;
    height: 100%;

    > div {
      display: flex;
      align-items: center;

      padding: 0;

      height: 100%;
      width: 100%;

      border-top: none;
    }
  }

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    position: absolute;

    flex-direction: row;

    > div {
      justify-content: ${({ textAlignment }) => (textAlignment === 'left' ? 'flex-end' : 'flex-start')};

      margin: 0;

      width: auto;
    }
  }
`;
