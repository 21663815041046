import styled from 'styled-components';

interface IAnswerContainer {
  showAnswer: boolean;
  maxHeight: number;
}

interface IQuestionContainer {
  showAnswer: boolean;
}

export const FaqContainer = styled.div`
  width: 100%;

  padding: ${({ theme }) => theme.core.sizing[1]}px 0;
`;

export const QuestionContainer = styled.button<IQuestionContainer>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.core.sizing[2]}px;

  width: 100%;

  background: none;
  border: none;
  padding: ${({ theme }) => theme.core.sizing[2]}px 0;

  cursor: pointer;

  text-align: start;

  font-size: ${({ theme }) => theme.core.fontSizes.l}px;
  font-weight: ${({ theme }) => theme.core.fontWeight.medium};

  color: ${({ theme }) => theme.core.colors.greyscale.black};

  svg {
    flex-shrink: 0;

    transform: rotate(${({ showAnswer }) => (showAnswer ? '180deg' : 0)});

    transition: all 0.3 ease-in;
  }
`;

export const AnswerContainer = styled.p<IAnswerContainer>`
  max-height: ${({ showAnswer, maxHeight }) => (showAnswer ? maxHeight : 0)}px;

  opacity: ${({ showAnswer }) => (showAnswer ? 1 : 0)};

  margin-bottom: ${({ theme, showAnswer }) => (showAnswer ? theme.core.sizing[1] : 0)}px;

  overflow: hidden;

  transition: all 0.25s ease-in-out;

  color: ${({ theme }) => theme.core.colors.greyscale.dark};
`;
