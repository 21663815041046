import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import AppWrapper from '../../components/appWrapper/appWrapper';

interface IError {
  errorMessage?: string;
}

const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding-top: 100px;

  h1 {
    font-family: ${({ theme }) => theme.core.fontFamily.jost};
  }

  a {
    font-size: ${({ theme }) => theme.core.fontSizes.l}px;

    text-decoration: none;
    color: ${({ theme }) => theme.core.colors.primary.green.medium};
  }

  img {
    width: 80%;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.m}`} {
    img {
      width: 400px;
    }
  }
`;

const Error = ({ errorMessage = 'Something Went Wrong!' }: IError) => {
  return (
    <>
      <Helmet>
        <title>Pata | Something went wrong</title>
        <meta name="description" content="We're sorry something went wrong. Return home to resuming using Pata." />
      </Helmet>
      <AppWrapper>
        <ErrorContainer>
          <img src={`${process.env.CDN_BASE_URL}/images/illustration-dog-digging-background.png`} />

          <h1>{errorMessage}</h1>
          <a href={process.env.WEB_BASE_URL}>Return Home 🦴</a>
        </ErrorContainer>
      </AppWrapper>
    </>
  );
};

export default Error;
