export const businessBenefits = [
  {
    title: 'Unlimited Appointments bookings',
    description: 'Manage, add, rebook, cancel - the power is in your hands',
  },
  {
    title: 'Unlimited clients and email notifications',
    description: 'Make customer communications swiftly and easily',
  },
  {
    title: 'Unlimited locations/workplaces/branches',
    description: 'Add as many places as you need',
  },
  {
    title: 'Unlimited team members ',
    description:
      'No matter how small or big your business is manage as many team members as your business needs at no extra cost',
  },
];
