import { WidthContainer } from '@pata-app/ui-document';

import React, { Fragment } from 'react';

import Faq from './components/faq';
import { faqsConfig } from './faq.config';
import { FaqsContainer, FaqsSection, HelpCentreLink } from './faqs.styled';

const Faqs = () => {
  return (
    <WidthContainer>
      <FaqsSection id="faq">
        <h2>
          <span>Have a question?</span>

          <span>
            See our <span>frequently asked questions</span>
          </span>
        </h2>
        <div>
          <FaqsContainer>
            {faqsConfig.map((faq, index) => (
              <Fragment key={faq.question}>
                <Faq question={faq.question} answer={faq.answer} />
                {index + 1 < faqsConfig.length && <hr />}
              </Fragment>
            ))}
          </FaqsContainer>

          <HelpCentreLink>
            Check out our
            <a href={`${process.env.HELP_CENTRE_URL}`} target="_blank">
              {' '}
              Help Centre{' '}
            </a>
            for more.
          </HelpCentreLink>
        </div>
      </FaqsSection>
    </WidthContainer>
  );
};

export default Faqs;
