import styled from 'styled-components';

export const TermsContainer = styled.div`
  margin: 0 ${({ theme }) => theme.core.sizing[1]}px;

  * {
    font-family: Arial !important;
    color: rgb(89, 89, 89) !important;
  }

  h4 {
    font-weight: 400;
  }

  h2,
  h1,
  h3 {
    span,
    a {
      color: #000 !important;
    }
  }

  h1 {
    text-transform: uppercase;
  }

  a {
    color: ${({ theme }) => theme.core.colors.primary.blue.medium};
  }

  .c15 {
    a {
      color: ${({ theme }) => theme.core.colors.greyscale.black};
    }
  }

  a.c0 {
    color: ${({ theme }) => theme.core.colors.greyscale.black};
  }
`;
