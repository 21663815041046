import styled from 'styled-components';

export const OurStoryContainer = styled.div`
  h2 {
    font-family: ${({ theme }) => theme.core.fontFamily.jost};
    font-size: ${({ theme }) => theme.core.fontSizes.xxxl}px;
    margin-top: 0;
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.s}`} {
    padding: ${({ theme }) => theme.core.sizing[4]}px;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    padding: ${({ theme }) => theme.core.sizing[4]}px ${({ theme }) => theme.core.sizing[4]}px
      ${({ theme }) => theme.core.sizing[4]}px 64px;
  }
`;
