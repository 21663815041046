export const MissionTileConfig = [
  {
    imageUrl: `${process.env.CDN_BASE_URL}/images/image-cat-and-dog-background.webp`,
    title: 'All about Pets',
    description:
      'At our core, we are focused to enrich the lives of every pet by providing accessible, helpful and trustworthy resources and services.',
  },
  {
    imageUrl: `${process.env.CDN_BASE_URL}/images/image-lady-with-dogs.webp`,
    title: 'Connection',
    description:
      'We seamlessly connect pets, pet parents and pet businesses on one trusted platform that centralises services, care and community.',
  },
  {
    imageUrl: `${process.env.CDN_BASE_URL}/images/image-dog-being-brushed.webp`,
    title: 'Stress-free Parents',
    description:
      'Pata is your partner along the pet parenting journey, providing support, tips, and a community dedicated to making every step easier.',
  },
];
