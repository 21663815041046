import styled from 'styled-components';

export const HeroSectionContainer = styled.section`
  position: relative;

  display: flex;
  justify-content: center;

  padding: ${({ theme }) => theme.core.sizing[2]}px;

  background: ${({ theme }) => theme.core.colors.primary.blue.medium};

  img {
    display: none;
  }

  @media ${({ theme }) => `${theme.core.breakpoints.m}`} {
    img {
      position: absolute;
      top: 0;

      display: block;

      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export const HeroContentContainer = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: ${({ theme }) => theme.core.sizing[2]}px;

  height: 500px;

  z-index: 2;

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    height: 400px;
  }
`;

export const HeroHeading = styled.h1`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.core.sizing[1]}px;

  margin: 0;

  margin-left: -2px;

  font-size: 50px;

  color: ${({ theme }) => theme.core.colors.greyscale.white};
`;

export const HeroSubHeading = styled.p`
  color: ${({ theme }) => theme.core.colors.greyscale.white};

  margin: 0;

  font-size: ${({ theme }) => theme.core.fontSizes.ml}px;
`;
