import React from 'react';
import { Helmet } from 'react-helmet';

import AppWrapper from '../../components/appWrapper/appWrapper';
import useScrollToLocation from '../../hooks/useScrollToLocation';
import BusinessBenefits from './components/businessBenefits/businessBenefits';
import { BusinessCategories } from './components/businessCategories/businessCategories';
import Faqs from './components/faqs/faqs';
import HeroSection from './components/heroSection/heroSection';
import { PaidFeatures } from './components/paidFeatures/paidFeatures';
import { SignUpProcess } from './components/signUpProcess/signUpProcess';
import { TopFeatures } from './components/topFeatures/topFeatures';

export const BusinessHome = () => {
  useScrollToLocation();

  return (
    <>
      <Helmet>
        <title>Pata for Business | Free Booking Platform for Pet Care Businesses</title>
        <meta
          name="description"
          content="Streamline your pet business with Pata's subscription-free booking platform. Manage admin, attract new clients, and grow your business — sign up today!"
        />
      </Helmet>
      <AppWrapper>
        <HeroSection />
        <TopFeatures />

        <BusinessBenefits />
        <PaidFeatures />
        <SignUpProcess />
        <BusinessCategories />

        <Faqs />
      </AppWrapper>
    </>
  );
};
