import styled from 'styled-components';

export const CategoriesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-rows: 150px;
  gap: ${({ theme }) => theme.core.sizing[2]}px;

  margin-top: 40px;

  @media ${({ theme }) => `${theme.core.breakpoints.l}`} {
    width: 70%;

    margin: auto;
    margin-top: 40px;
  }
`;
export const CategoryContainer = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.core.sizing[2]}px;

  border-radius: ${({ theme }) => theme.core.border.container.radius}px;

  border: 0.5px solid ${({ theme }) => theme.core.colors.greyscale.medium};

  cursor: pointer;

  transition: 0.3s all ease-in-out;

  font-weight: ${({ theme }) => theme.core.fontWeight.semiBold};
  color: ${({ theme }) => theme.core.colors.greyscale.black};
  text-decoration: none;

  &:hover {
    background: ${({ theme }) => theme.core.colors.greyscale.light};
    border-color: ${({ theme }) => theme.core.colors.primary.green.medium};
  }

  svg {
    fill: ${({ theme }) => theme.core.colors.primary.green.medium};
  }
`;
